import React from 'react'
import Parse from 'parse'
import { makeAutoObservable } from 'mobx'
import moment, { Moment } from 'moment'
import { UserRole } from '../Models/UserRole'
import { userStore } from './userStore'
import { LOCATION_TYPE } from '../views/events3/components/CreateLocation'
import { File } from '../Models/File'
import { School } from '../Models/School'
import {
  ARCHIVE_STATE,
  USER_ROLE_STATUS,
  USER_ROLE_TYPE,
} from '../Enums/TyraEnums'
import { Location, LocationInterface } from '../Models/Location'
import { EventListItemData } from '../views/events3/Events3'
import { TagInterface } from '../Models/Tag'
import { tagStore } from './tagStore'
import { StaffEventsRead } from './fileJobStore'

export type IsoDateTime = string // Exempel: 2022-11-29T17:05:07
export type IsoDate = string // Exempel: 2022-11-29
export type IsoTime = string // Exempel: 17:05:07

export enum EVENT3_TYPE {
  INFO = 'info',
  IMPORTANT = 'important',
  BOOKING = 'booking',
  MEETING = 'meeting',
  ACTIVITIES = 'activities',
}

export const enum EVENT3_USER_FOR {
  ROLE_PLUS = 'role_plus',
  ROLE = 'role',
  KID_PLUS = 'kid_plus',
  KID = 'kid',
}

export const enum EVENT3_POST_TO {
  ALL = 'all',
  ONLY_STAFF = 'only_staff',
  MULTI_SCHOOLS = 'multi_schools',
  SPECIFIC_USERS = 'specific_users',
}

export interface Times {
  date: IsoDate
  start?: IsoDateTime
  end?: IsoDateTime
  fakeId: string
}

export interface RecurrenceParams {
  startsOn: IsoDate
  endsOn: IsoDate
  interval?: number //defaults to 1 if undefined
  days: string[]
  startTime?: IsoTime
  endTime?: IsoTime
}

export interface CreateEventParams {
  mod?: string
  multiSchools?: string[]
  //schoolId: string
  //userRoleId: string
  eventType: EVENT3_TYPE
  eventFor?: string

  startDate?: IsoDate
  stopDate?: IsoDate
  times?: {
    date: IsoDate
    start?: IsoDateTime
    end?: IsoDateTime
    fakeId: string
  }[]

  topic?: string
  content?: string
  files?: any[] // This is only for UI perpuses, do not send to cloud
  fileIds?: string[]
  tagIds?: string[]
  location?: LocationInterface
  locationType: LOCATION_TYPE

  depIds?: string[]

  postTo?: EVENT3_POST_TO // This is only for UI perpuses, do not send to cloud
  onlyStaff?: boolean
  destKids?: string[]
  destRoles?: string[]

  dates?: IsoDate[]
  isPinned?: boolean
  isDraft?: boolean
  maxParticipants?: number
  publishAs?: string
  eventResponsible?: string[]
  recurrence?: RecurrenceParams
  isRecurrence?: boolean
  allowSeparateBookings?: string[]
  addParticipatingKids?: String[]
  allowComments: boolean
  specificUserRoleIds?: string[]
}

export interface CreateEventCloudParams {
  schoolId: string
  userRoleId: string
  eventType: EVENT3_TYPE
  eventFor?: string

  startDate: IsoDate
  stopDate?: IsoDate
  times?: string[]

  topic?: string
  content?: string
  fileIds?: string[]
  tagIds?: string[]
  locationId?: string[]

  depIds?: string[]

  onlyStaff?: boolean
  destKids?: string[]
  destRoles?: string[]

  dates?: IsoDate[]
  isPinned?: boolean
  isDraft?: boolean
  maxParticipants?: number
  publishAs?: string
  eventResponsible?: string[]
  sendPush: boolean
  recurrence?: string
  allowSeparateBookings?: string[]
  addParticipatingKids?: String[]
  allowComments?: boolean
  specificRoleIds?: string[]
}

export interface StatusSummary {
  attending: number
  not_attending: number
  not_replied: number
  messages: number
  free_times?: number | undefined
}

export interface ListToBookMeetingResponse {
  event_type: string
  topic: string
  content?: string
  eventResponsible?: EventResponsibleResponse[]
  fromDate: IsoDate
  toDate: IsoDate
  groupInfoId: string
  tags?: TagInterface[]
  author?: string
  statusSummary?: StatusSummary
}

export interface EventResponsibleResponse {
  title: string
  image?: string
  type: 'dep' | 'school' | 'text' | 'user' | 'role' | 'alternative'
  id: string
}

export interface EventTimeSmallInterface {
  booked_by_kid_id?: string
  start_isodate?: IsoDate
  stop_isodate?: IsoDate
  start_time?: IsoTime
  end_time?: IsoTime
  start_date?: Date
  stop_date?: Date
  id?: string
}

export interface EventCommentInterface {
  id: string
  name: string
  thumbnail: string | undefined
  comment: string
  postTime: IsoDateTime
  editTime?: IsoDateTime
  isOwner: boolean
}

export interface FileInterface {
  id?: string
  name?: string
  file: string
  thumbnail?: string
  mime_type?: string
  metadata?: any
}

export enum BookingStatus {
  ATTENDING = 'attending',
  NOT_ATTENDING = 'not_attending',
  NOT_REPLIED = 'not_replied',
  FULLY_BOOKED = 'fully_booked',
}

export interface EventInfoResponse {
  eventTimeId?: string
  eventGroupInfoId: string
  departmentIds: string[]
  type: EVENT3_TYPE
  topic?: string
  content?: string
  languageCode?: string
  eventDate: IsoDate
  stopDate?: IsoDate
  times?: EventTimeSmallInterface[]
  comments?: EventCommentInterface[]
  tags?: TagInterface[]
  files?: FileInterface[]
  location?: LocationInterface
  //isRead: boolean;
  isFavourite: boolean
  bookingStatus?: BookingStatus
  onlyStaff?: boolean
  kidImageUrl?: string
  publisherName?: string
  publisherImage?: string
  publishDate?: string
  eventFor?: string
  statusSummary?: StatusSummary
  placesLeft?: number
  maxParticipants?: number
  hasMultipleTimes: boolean
  isRecurring: boolean
  isPinned?: boolean
  createdAt: IsoDateTime
  ownParticipantsData?: {
    id: string
    message?: string
    extraParticipants?: number
  }
  eventResponsible?: EventResponsibleResponse[]
  bookingOverview?: BookingOverview | undefined
  presentGuardians?: EventResponsibleResponse[]
  kidId?: string
  kidName?: string
  changedFiles?: boolean
  isOwner: boolean
  separateBookingKidIds?: string[] | undefined
  allowSeparateBooking?: boolean | undefined
  alreadyBooked?: AlreadyBooked | undefined
  allowComments?: boolean
  specificRoleIds?: string[] | undefined
}

export interface AlreadyBooked {
  eventTimeId: string
  participantId: string
  kidId: string | undefined
  kidName: string
  time: TimeSpan
}

export interface TimeSpan {
  date: IsoDate
  start?: IsoTime
  end?: IsoTime
  isBoooked?: boolean
}

export interface BookingOverview {
  bookedMeetings: BookingOverviewObject[]
  freeTimes: BookingOverviewObject[]
  canceledMeetings: BookingOverviewObject[]
  kidsNotBooked: BookingOverviewObject[]
  messages: BookingOverviewObject[]
}

export enum BookingOverviewObjectType {
  BOOKED = 'booked',
  FREE = 'free',
  CANCELLED = 'cancelled',
  NOT_BOOKED = 'not_booked',
  HAS_MESSAGE = 'has_message',
}

export interface BookingOverviewObject {
  type: BookingOverviewObjectType
  name?: string | undefined
  imageUrl?: string | undefined
  eventTimeId?: string | undefined
  departmentName?: string | undefined
  kidId?: string | undefined
  date?: IsoDate | undefined
  startTime?: IsoTime | undefined
  endTime?: IsoTime | undefined
  message?: string | undefined
  messageBy?: string | undefined
  messageAt?: IsoDateTime | undefined
  cancelledAt?: IsoDate | undefined
  rebookedTo?: IsoDate | undefined
  presentGuardians?: EventResponsibleResponse[] | undefined
}

export interface FreeTimesToBook {
  eventTimeId: string
  date: string | undefined
  startTime: string | undefined
  endTime: string | undefined
}

class Event3Store {
  locations?: Location[]
  allEvents?: EventListItemData[]
  events?: EventListItemData[]
  meetingsToBook?: string[]
  currentMeetings?: string[]
  listToBookMeeting?: ListToBookMeetingResponse[]
  selectedCalendarDate?: IsoDate
  currentMonth?: Moment
  lastFetched?: { start: string; end: string }
  eventsLoading: boolean = false
  detailedInfoFromGroupInfoId?: EventInfoResponse
  prevUserRoleId?: string
  refreshEvents?: boolean
  freeTimesToBook?: FreeTimesToBook[]
  selectedDepartmentIds?: string[] | undefined
  selectedTypes?: EVENT3_TYPE[] | undefined
  godMode?: boolean
  typeOfStaffEvent: StaffEventsRead = StaffEventsRead.NONE_STAFF_EVENTS

  constructor() {
    makeAutoObservable(this)
    this.setPreviousUserRoleId(userStore?.currentUserRole?.id)
  }

  setSelectedDepartmentIds = (departmentIds: string[] | undefined) => {
    this.selectedDepartmentIds = departmentIds
  }

  setTypeOfStaffEvent = (typeOfStaffEvent: StaffEventsRead) => {
    this.typeOfStaffEvent = typeOfStaffEvent
  }

  setSelectedTypes = (selectedTypes: EVENT3_TYPE[] | undefined) => {
    this.selectedTypes = selectedTypes
  }
  setGodMode = (godMode: boolean) => {
    this.godMode = godMode
  }
  saveFilters = () => {
    this.refreshEvents = true
    this.getEvents()
  }
  setDetailedInfoFromGroupInfoId(
    detailedInfoFromGroupInfoId: EventInfoResponse,
  ) {
    this.detailedInfoFromGroupInfoId = detailedInfoFromGroupInfoId
  }

  setFreeTimesToBook(freeTimesToBook: FreeTimesToBook[]) {
    this.freeTimesToBook = freeTimesToBook
  }

  setListToBookMeeting(listToBookMeeting: ListToBookMeetingResponse[]) {
    this.listToBookMeeting = listToBookMeeting
  }
  setRefreshEvents(value: boolean) {
    this.refreshEvents = value
  }
  setEventsLoading(value: boolean) {
    this.eventsLoading = value
  }
  setCurrentMonth(date: Moment) {
    this.currentMonth = date
  }
  setLastFetched({ start, end }: { start: string; end: string }) {
    this.lastFetched = { start: start, end: end }
  }

  setLocations(locations: Location[]) {
    this.locations = locations
  }

  setAllEvents(events: EventListItemData[]) {
    this.allEvents = events
  }
  setEvents(events: EventListItemData[]) {
    this.events = events
  }

  setMeetingsToBook(meetingsToBook: string[]) {
    this.meetingsToBook = meetingsToBook
  }

  setCurrentMeetings(currentMeetings: string[]) {
    this.currentMeetings = currentMeetings
  }

  setPreviousUserRoleId(id: string | undefined) {
    this.prevUserRoleId = id
  }

  setSelectedCalendarDate(date?: IsoDate) {
    this.selectedCalendarDate = date
  }

  changeCurrentMonth = async (date: Moment) => {
    this.setCurrentMonth(date)
  }

  changeSelectedCalendarDate = async (date?: IsoDate) => {
    this.setSelectedCalendarDate(date)
    if (date) {
      if (this.events) {
        const allEvents = this.allEvents

        if (allEvents) {
          if (allEvents.some((event) => event.eventDate === date) === true)
            this.setEvents(
              allEvents.filter((event) => event.eventDate === date),
            )
          else return
        }
      }
    } else {
      if (this.allEvents) {
        this.setEvents(this.allEvents)
      }
    }
  }

  uploadFileToParse = async (
    file: any,
    userRole: UserRole,
    schoolId: string,
  ) => {
    const currentUserRole = userStore.currentUserRole

    var extension = file.name.split('.').pop()
    var parseFile = new Parse.File('TyraFile.' + extension, file)

    await parseFile.save()

    const newFile = new File()
    newFile.name = file.name
    newFile.mime_type = file.type
    newFile.uploader = userRole
    newFile.metadata = {} // Fixa detta sen - gäller audiofiler
    newFile.school_pointer = School.createWithoutData(schoolId) as School
    newFile.file = parseFile
    await newFile.save()
    return newFile
  }

  fetchLocations = async (schoolIds: string[]) => {
    const locationsQuery = new Parse.Query(Location)
    locationsQuery.containedIn('school_pointer', schoolIds)
    locationsQuery.doesNotExist('archive_state')
    locationsQuery.exists(Location.Fields.SHARED)

    const locations = await locationsQuery.find()
    if (locations) {
      this.setLocations(locations)
    }
  }

  saveLocation = async (
    location: LocationInterface,
    schoolIds: string[],
    shared?: string,
  ) => {
    const currentUserRole = userStore.currentUserRole
    for (const schoolId of schoolIds) {
      const newLocation = new Location()
      newLocation.name = location.name
      newLocation.address = location.address
      newLocation.lat = location.lat
      newLocation.long = location.long
      newLocation.zipcode = location.zipcode
      newLocation.city = location.city
      newLocation.shared = shared
      newLocation.type = location.type
      newLocation.school = School.createWithoutData(schoolId) as School
      newLocation.owner = currentUserRole

      await newLocation.save()
    }
  }

  archiveLocation = async (locationId: string) => {
    const currentUserRole = userStore.currentUserRole
    const location = await new Parse.Query(Location).get(locationId)
    if (location) {
      location.set(Location.Fields.ARCHIVE_STATE, ARCHIVE_STATE.HISTORIC)
      location.set(Location.Fields.ARCHIVED_BY, currentUserRole)
      await location.save()
    }
  }

  shareLocation = async (locationId: string, value: string) => {
    const location = await new Parse.Query(Location).get(locationId)
    if (location) {
      location.set(Location.Fields.SHARED, value)
      await location.save()
    }
  }

  createEvent = async (event: CreateEventParams, sendPush: boolean) => {
    /*
TODO:
- Skapa filer [x]
- Fler inställningar på filer []
- Multischools [x]
- Location
- Flera dagar
*/
    // event.eventResponsible = ['text:Fritext Whohoo']
    if (!event.isRecurrence) {
      event.recurrence = undefined
    } else {
      // remove times, otherwise error in cloud
      event.times = []
    }
    if (event.times === undefined || event.times.length === 0) {
      event.times = [{ date: moment().format('YYYY-MM-DD'), fakeId: '' }]
    }

    const startDate = event.times.reduce((a, b) =>
      a.date < b.date ? a : b,
    ).date
    const stopDate = event.times.reduce((a, b) =>
      a.date > b.date ? a : b,
    ).date

    const currentUserRole = userStore.currentUserRole

    if (!currentUserRole) {
      throw new Error('No userrole')
    }

    if (event.multiSchools) {
      const usersRolesQuery = new Parse.Query(UserRole)
      usersRolesQuery.equalTo('user', userStore.currentUser)
      usersRolesQuery.greaterThanOrEqualTo('role_type', USER_ROLE_TYPE.ADMIN)
      usersRolesQuery.doesNotExist('archive_state')
      usersRolesQuery.equalTo('status', USER_ROLE_STATUS.ACTIVE)
      const adminUserRoles = await usersRolesQuery.find()

      let isAdminForCurrentSchool = false

      if (
        adminUserRoles.find(
          (uR) => uR.school_pointer.id === currentUserRole.school_pointer.id,
        )
      ) {
        isAdminForCurrentSchool = true
      }

      if (!isAdminForCurrentSchool) {
        if (event.multiSchools.length > 1) {
          throw new Error('No admin userRole')
        }
        if (event.multiSchools.length === 1) {
          if (event.multiSchools[0] !== currentUserRole.school_pointer.id) {
            throw new Error('No admin userRole')
          }
        }
      }

      for (const schoolId of event.multiSchools) {
        if (event.files) {
          // Skapa Parse Files
          const files = []
          for (const file of event.files) {
            const newFile = await this.uploadFileToParse(
              file,
              currentUserRole,
              schoolId,
            )
            files.push(newFile)
          }

          event.fileIds = files.map((file) => file.id)
        }

        const adminUserRole = adminUserRoles.find(
          (uR) => uR.school_pointer.id === schoolId,
        )
        /*if (!adminUserRole) {
          throw new Error('No admin userRole')
        }*/
        let locationIds: string[] = []
        if (event.location) {
          if (event.location.id) {
            locationIds.push(event.location.id)
          } else {
            const location = new Location()
            location.owner = isAdminForCurrentSchool
              ? adminUserRole
              : currentUserRole
            location.school = isAdminForCurrentSchool
              ? adminUserRole!.school_pointer
              : currentUserRole.school_pointer
            location.name = event.location.name
            location.address = event.location.address
            location.lat = event.location.lat
            location.long = event.location.long
            location.zipcode = event.location.zipcode
            location.city = event.location.city
            location.shared = event.location.shared
            location.type = event.location.type
            const newLocation = await location.save()
            locationIds.push(newLocation.id)
          }
        }
        const newEvent: CreateEventCloudParams = {
          schoolId: schoolId,
          userRoleId: isAdminForCurrentSchool
            ? adminUserRole!.id
            : currentUserRole.id,
          eventType: event.eventType,
          eventFor: event.eventFor,
          startDate: startDate,
          stopDate: stopDate,
          times: event.times?.map((time) => JSON.stringify(time)), // In cloud, this is string and not json object
          topic: event.topic,
          content: event.content,
          fileIds: event.fileIds,
          tagIds: event.tagIds,
          locationId: locationIds, //event.location,
          depIds: event.depIds,
          onlyStaff: event.onlyStaff,
          destKids: event.destKids,
          destRoles: event.destRoles,
          dates: event.dates,
          isPinned: event.isPinned,
          sendPush: sendPush,
          publishAs:
            event.publishAs === 'undefined' ? undefined : event.publishAs,
          eventResponsible: event.eventResponsible,
          recurrence: JSON.stringify(event.recurrence),
          allowSeparateBookings: event.allowSeparateBookings,
          addParticipatingKids: event.addParticipatingKids,
          maxParticipants: event.maxParticipants,
          allowComments: event.allowComments,
          specificRoleIds:
            event.multiSchools.length > 1
              ? undefined
              : event.specificUserRoleIds?.length
              ? [...event.specificUserRoleIds, currentUserRole.id]
              : undefined,
        }

        const createEvent = await Parse.Cloud.run('createEvent', newEvent)
        this.setRefreshEvents(true)
      }
    } else {
      if (event.files) {
        // Skapa Parse Files
        const files = []
        for (const file of event.files) {
          const newFile = await this.uploadFileToParse(
            file,
            currentUserRole,
            currentUserRole.school_pointer.id,
          )
          files.push(newFile)
        }

        event.fileIds = files.map((file) => file.id)
      }

      let locationIds: string[] = []
      if (event.location) {
        if (event.location.id) {
          locationIds.push(event.location.id)
        } else {
          const location = new Location()
          location.owner = currentUserRole
          location.school = currentUserRole.school_pointer
          location.name = event.location.name
          location.address = event.location.address
          location.lat = event.location.lat
          location.long = event.location.long
          location.zipcode = event.location.zipcode
          location.city = event.location.city
          location.shared = event.location.shared
          location.type = event.location.type
          const newLocation = await location.save()
          locationIds.push(newLocation.id)
        }
      }

      const newEvent: CreateEventCloudParams = {
        schoolId: currentUserRole.school_pointer.id,
        userRoleId: currentUserRole.id,
        eventType: event.eventType,
        eventFor: event.eventFor,
        startDate: startDate,
        stopDate: stopDate,
        times: event.times?.map((time) => JSON.stringify(time)), // In cloud, this is string and not json object
        topic: event.topic,
        content: event.content,
        fileIds: event.fileIds,
        tagIds: event.tagIds,
        locationId: locationIds, //event.location,
        depIds: event.depIds,
        onlyStaff: event.onlyStaff,
        destKids: event.destKids,
        destRoles: event.destRoles,
        dates: event.dates,
        isPinned: event.isPinned,
        sendPush: sendPush,
        publishAs:
          event.publishAs === 'undefined' ? undefined : event.publishAs,
        eventResponsible: event.eventResponsible,
        recurrence: JSON.stringify(event.recurrence),
        allowSeparateBookings: event.allowSeparateBookings,
        addParticipatingKids: event.addParticipatingKids,
        maxParticipants: event.maxParticipants,
        allowComments: event.allowComments,
        specificRoleIds: event.specificUserRoleIds?.length
          ? [...event.specificUserRoleIds, currentUserRole.id]
          : undefined,
      }

      const createEvent = await Parse.Cloud.run('createEvent', newEvent)
      this.setRefreshEvents(true)
    }
  }

  getEvents = async () => {
    if (userStore.currentUserRole && userStore.currentUserRole.school_pointer) {
      let newFetch = false
      const includeBookings = true

      if (
        (this.prevUserRoleId !== userStore.currentUserRole.id &&
          moment(this.currentMonth).format('YYYY-MM-DD') >
            moment(this.lastFetched?.start).format('YYYY-MM-DD')) ||
        !this.lastFetched ||
        moment(this.lastFetched.start).format('YYYY-MM-DD') >
          moment(this.currentMonth).format('YYYY-MM-DD') ||
        moment(this.lastFetched.end).format('YYYY-MM-DD') <
          moment(this.currentMonth).format('YYYY-MM-DD') ||
        this.refreshEvents === true
      ) {
        newFetch = true

        this.setEventsLoading(true)
        const events = await Parse.Cloud.run('getEvents3', {
          schoolId: userStore.currentUserRole.school_pointer.id,
          userRoleId: userStore.currentUserRole.id,
          departmentIds: this.selectedDepartmentIds,
          tagsIds: tagStore.selectedTagIds,
          eventTypes: this.selectedTypes,
          godMode: this.godMode,
          dateStart: this.currentMonth
            ? moment(this.currentMonth)
                .subtract(2, 'months')
                .startOf('month')
                .format('YYYY-MM-DD')
            : moment()
                .subtract(2, 'months')
                .startOf('month')
                .format('YYYY-MM-DD'),
          dateEnd: this.currentMonth
            ? moment(this.currentMonth)
                .add(2, 'years')
                .endOf('month')
                .format('YYYY-MM-DD')
            : moment().add(2, 'years').endOf('month').format('YYYY-MM-DD'),
          includeBookings: includeBookings,
        }).finally(() => {
          this.setLastFetched({
            start: this.currentMonth
              ? moment(this.currentMonth)
                  .subtract(2, 'months')
                  .startOf('month')
                  .format('YYYY-MM-DD')
              : moment()
                  .subtract(2, 'months')
                  .startOf('month')
                  .format('YYYY-MM-DD'),
            end: this.currentMonth
              ? moment(this.currentMonth)
                  .add(2, 'years')
                  .endOf('month')
                  .format('YYYY-MM-DD')
              : moment().add(2, 'years').endOf('month').format('YYYY-MM-DD'),
          })
          this.setPreviousUserRoleId(userStore?.currentUserRole?.id)
          this.setRefreshEvents(false)
          this.setEventsLoading(false)
        })
        //return events
        if (includeBookings) {
          this.setEvents(
            events.events.filter(
              (event: any) =>
                moment(event.eventDate).format('YYYY-MM') ===
                moment(this.currentMonth).format('YYYY-MM'),
            ),
          )
          this.setAllEvents(events.events)
          if (events?.bookings?.me) {
            this.setMeetingsToBook(events.bookings.me)
          }
          if (events?.bookings?.others) {
            this.setCurrentMeetings(events.bookings.others)
          }
        } else {
          this.setEvents(
            events.filter(
              (event: any) =>
                moment(event.eventDate).format('YYYY-MM') ===
                moment(this.currentMonth).format('YYYY-MM'),
            ),
          )
          this.setAllEvents(events)
        }
      }
      if (this.lastFetched && !newFetch && this.allEvents) {
        if (includeBookings) {
          this.setEvents(
            this.allEvents?.filter(
              (event: any) =>
                moment(event.eventDate).format('YYYY-MM') ===
                moment(this.currentMonth).format('YYYY-MM'),
            ),
          )
        } else {
          this.setEvents(
            this.allEvents?.filter(
              (event: any) =>
                moment(event.eventDate).format('YYYY-MM') ===
                moment(this.currentMonth).format('YYYY-MM'),
            ),
          )
        }
      }
    }
  }

  getDetailedEvent = async (eventTimeId: string | undefined) => {
    if (userStore.currentUserRole && userStore.currentUserRole.school_pointer) {
      const event = await Parse.Cloud.run('getDetailedEvent', {
        schoolId: userStore.currentUserRole.school_pointer.id,
        userRoleId: userStore.currentUserRole.id,
        eventTimeId: eventTimeId,
      })
      return event
    }
  }

  getEventOverview = async () => {
    if (userStore.currentUserRole && userStore.currentUserRole.school_pointer) {
      const eventOverview = await Parse.Cloud.run('getEventOverview', {
        schoolId: '7FuxpelCzk',
        userRoleId: 'yqriR5gEpd',
        eventTimeId: 'fQu97Ar8Dt',
        eventGroupInfoId: 'HSpCy9mcVI',
        depIds: ['fc3H1SA5IW'],
      })
    }
  }

  getEvent3Overview = async (
    eventTimeId: string | undefined,
    eventGroupInfoId: string | undefined,
    departmentIds: string[],
  ) => {
    if (userStore.currentUserRole && userStore.currentUserRole.school_pointer) {
      const eventOverview = await Parse.Cloud.run('getEvent3Overview', {
        schoolId: userStore.currentUserRole.school_pointer.id,
        userRoleId: userStore.currentUserRole.id,
        eventTimeId: eventTimeId,
        eventGroupInfoId: eventGroupInfoId,
        depIds: departmentIds,
      })
      return eventOverview
    }
  }

  createEventComment = async (
    eventTimeId: string | undefined,
    eventGroupInfoId: string | undefined,
    message: string,
  ) => {
    const newComment = {
      schoolId: userStore.currentUserRole?.school_pointer.id,
      userRoleId: userStore.currentUserRole?.id,
      eventTimeId: eventTimeId,
      eventGroupInfoId: eventGroupInfoId,
      message: message,
    }

    if (userStore.currentUserRole && userStore.currentUserRole.school_pointer) {
      const eventComment = await Parse.Cloud.run('makeEventComment', newComment)
    }
  }

  bookAMeeting = async () => {
    if (userStore.currentUserRole && userStore.currentUserRole.school_pointer) {
      const bookToEventTime = await Parse.Cloud.run('bookToEventTime', {
        schoolId: '7FuxpelCzk',
        userRoleId: 'yqriR5gEpd',
        eventTimeId: '6zFOFd80Cc',
        //eventGroupInfoId: 'HSpCy9mcVI',
        depIds: ['fc3H1SA5IW'],
        bookingType: 'cancelled',
      })
    }
  }

  acceptParticipatingInEventTESTING = async (
    eventTimeId?: string | undefined,
    userIds?: string[],
    kidIds?: string[] | undefined,
    message?: string,
    extraParticipants?: number,
  ) => {
    const eventId = '1jB3xm4SG7'
    const kidId = 'JZWjj2lDhI'
    if (userStore.currentUserRole && userStore.currentUserRole.school_pointer) {
      const bookToEventTime = await Parse.Cloud.run(
        'acceptBookingToEventTime',
        {
          userRoleId: userStore.currentUserRole?.id,
          eventTimeId: '1jB3xm4SG7',
          userIds: userIds?.map((id) => {
            return id
          }),
          kidIds: ['JZWjj2lDhI'],
          message: undefined,
          extraParticipants: undefined,
        },
      )
      this.setRefreshEvents(true)
    }
  }

  rebookToEventTime = async (
    schoolId: string,
    userRoleId: string,
    eventTimeId: string,
    oldEventTimeId: string,
    kidIds: string[],
    removeTime: boolean,
    extraPresentUserRoleIds: string[], // For Booking
    message?: string,
    ignoreDetailedResponse?: boolean, // For Booking
  ) => {
    const rebookToEventTime = await Parse.Cloud.run('rebookToEventTime', {
      schoolId,
      userRoleId,
      eventTimeId,
      oldEventTimeId,
      kidIds,
      removeTime,
      extraPresentUserRoleIds, // For Booking
      message,
      ignoreDetailedResponse, // For Booking
    }).finally(async () => {
      this.setRefreshEvents(true)
      await this.getEvents()
    })
  }

  acceptParticipatingInEvent = async (
    eventTimeId: string | undefined,
    userIds?: string[],
    kidIds?: string[] | undefined,
    message?: string,
    extraParticipants?: number,
    ignoreDetailedResponse?: boolean, // For Booking
    extraPresentUserRoleIds?: string[], // For Booking
  ) => {
    if (userStore.currentUserRole && userStore.currentUserRole.school_pointer) {
      const bookToEventTime = await Parse.Cloud.run(
        'acceptBookingToEventTime',
        {
          userRoleId: userStore.currentUserRole?.id,
          eventTimeId: eventTimeId,
          userIds: userIds?.map((id) => {
            return id
          }),
          kidIds: kidIds?.map((id) => {
            return id
          }),
          message: message,
          extraParticipants: extraParticipants,
          ignoreDetailedResponse: ignoreDetailedResponse,
          extraPresentUserRoleIds: extraPresentUserRoleIds,
        },
      )
      if (this.events && this.allEvents) {
        const updatedEvents = this.events.map((event) =>
          event.eventTimeId === eventTimeId
            ? { ...event, bookingStatus: 'attending' } // Mark as unread
            : event,
        )

        const updatedAllEvents = this.allEvents.map((event) =>
          event.eventTimeId === eventTimeId
            ? { ...event, bookingStatus: 'attending' } // Mark as unread
            : event,
        )

        this.setEvents(updatedEvents) // Update state with new references
        this.setAllEvents(updatedAllEvents) // Update state with new references
      }
    }
  }
  declineParticipatingInEvent = async (
    eventTimeId: string | undefined,
    userIds?: string[],
    kidIds?: string[],
  ) => {
    if (userStore.currentUserRole && userStore.currentUserRole.school_pointer) {
      const bookToEventTime = await Parse.Cloud.run(
        'declineBookingToEventTime',
        {
          userRoleId: userStore.currentUserRole?.id,
          eventTimeId: eventTimeId,
          userIds: userIds?.map((id) => {
            return id
          }),
          kidIds: kidIds?.map((id) => {
            return id
          }),
        },
      )
      if (this.events && this.allEvents) {
        const updatedEvents = this.events.map((event) =>
          event.eventTimeId === eventTimeId
            ? { ...event, bookingStatus: 'not_attending' } // Mark as unread
            : event,
        )

        const updatedAllEvents = this.allEvents.map((event) =>
          event.eventTimeId === eventTimeId
            ? { ...event, bookingStatus: 'not_attending' } // Mark as unread
            : event,
        )

        this.setEvents(updatedEvents) // Update state with new references
        this.setAllEvents(updatedAllEvents) // Update state with new references
      }
    }
  }

  declineBookingToEventTime = async (
    schoolId: string,
    userRoleId: string,
    eventTimeId: string,
    kidIds: string[],
    ignoreDetailedResponse: boolean,
    removeTime: boolean,
  ) => {
    const declineBookingToEventTime = await Parse.Cloud.run(
      'declineBookingToEventTime',
      {
        schoolId,
        userRoleId,
        eventTimeId,
        kidIds,
        ignoreDetailedResponse,
        removeTime,
      },
    )
    this.setRefreshEvents(true)
    this.getEvents()
  }

  editBookingToEventTime = async (
    eventTimeId: string | undefined,
    participantId: string | undefined,
    message?: string,
    deleteMessage?: boolean,
    extraParticipants?: number,
    extraPresentUserRoleIds?: string[],
  ) => {
    if (userStore.currentUserRole && userStore.currentUserRole.school_pointer) {
      const editedBookToEventTime = await Parse.Cloud.run(
        'editBookingToEventTime',
        {
          userRoleId: userStore.currentUserRole?.id,
          eventTimeId: eventTimeId,
          participantId: participantId,
          message: message,
          deleteMessage: deleteMessage,
          extraParticipants: extraParticipants,
          extraPresentUserRoleIds: extraPresentUserRoleIds,
        },
      )
      return editedBookToEventTime
    }
  }

  setEventsAsRead = async () => {
    if (userStore.currentUserRole && userStore.currentUserRole.school_pointer) {
      // Get the list of unread events and mark them as read on the backend
      await Parse.Cloud.run('readEvents', {
        schoolId: userStore.currentUserRole.school_pointer.id,
        userRoleId: userStore.currentUserRole.id,
        groupIds: this.allEvents
          ?.filter((event) => event.isRead === false) // Only get unread events
          .map((event) => event.eventGroupInfoId), // Get their group IDs
      })
    }

    // Update the local state to mark events as read
    if (this.events && this.allEvents) {
      const updatedEvents = this.events.map(
        (event) =>
          event.isRead === false ? { ...event, isRead: true } : event, // Mark as read
      )

      const updatedAllEvents = this.allEvents.map(
        (event) =>
          event.isRead === false ? { ...event, isRead: true } : event, // Mark as read
      )

      this.setEvents(updatedEvents) // Update the events state
      this.setAllEvents(updatedAllEvents) // Update the allEvents state
    }
  }

  setEventsAsUnRead = async (eventGroupInfoId?: string) => {
    if (userStore.currentUserRole && userStore.currentUserRole.school_pointer) {
      const event = await Parse.Cloud.run('unreadEvents', {
        schoolId: userStore.currentUserRole.school_pointer.id,
        userRoleId: userStore.currentUserRole.id,
        groupIds: [eventGroupInfoId],
      })
    }
    // Update the state directly by marking the event as unread
    if (this.events && this.allEvents) {
      const updatedEvents = this.events.map((event) =>
        event.eventGroupInfoId === eventGroupInfoId
          ? { ...event, isRead: false } // Mark as unread
          : event,
      )

      const updatedAllEvents = this.allEvents.map((event) =>
        event.eventGroupInfoId === eventGroupInfoId
          ? { ...event, isRead: false } // Mark as unread
          : event,
      )

      this.setEvents(updatedEvents) // Update state with new references
      this.setAllEvents(updatedAllEvents) // Update state with new references
    }
  }
  sendReminder = async (
    eventTimeId?: string | undefined,
    userIds?: string[],
    eventGroupInfoId?: string,
    kidIds?: string[],
  ) => {
    if (userStore.currentUserRole && userStore.currentUserRole.school_pointer) {
      const pushStatus = await Parse.Cloud.run('pushEventReminder', {
        userRoleId: userStore.currentUserRole.id,
        eventTimeId,
        userIds,
        eventGroupInfoId,
        kidIds,
      })
    }
  }

  isPresentOnEventTime = async (
    eventTimeId: string | undefined,
    participantsId: string[],
    present: boolean | undefined,
  ) => {
    if (userStore.currentUserRole && userStore.currentUserRole.school_pointer) {
      const presentStatus = await Parse.Cloud.run('isPresentOnEventTime', {
        userRoleId: userStore.currentUserRole.id,
        eventTimeId: eventTimeId,
        participantsId: participantsId,
        isPresent: present,
      })
    }
  }

  getListToBookMeeting = async () => {
    const userRole = userStore.currentUserRole
    if (userRole && userRole.school_pointer) {
      const listToBookMeeting = await Parse.Cloud.run('getListToBookMeeting', {
        userRoleId: userRole?.id,
        groupInfoIds:
          userRole.role_type > USER_ROLE_TYPE.PARENT
            ? this.currentMeetings
            : this.meetingsToBook,
        schoolId: userRole.school_pointer.id,
      })
      this.setListToBookMeeting(listToBookMeeting)
    }
  }

  getDetailedInfoFromGroupInfoId = async (groupInfoId: string) => {
    if (userStore.currentUserRole && userStore.currentUserRole.school_pointer) {
      const detailedInfoFromGroupInfoId = await Parse.Cloud.run(
        'getDetailedInfoFromGroupInfoId',
        {
          userRoleId: userStore.currentUserRole?.id,
          eventGroupInfoId: groupInfoId,
          schoolId: userStore.currentUserRole.school_pointer.id,
        },
      )
      this.setDetailedInfoFromGroupInfoId(detailedInfoFromGroupInfoId)
    }
  }

  getFreeTimesToBook = async (groupInfoId: string) => {
    if (userStore.currentUserRole && userStore.currentUserRole.school_pointer) {
      const freeTimesToBook = await Parse.Cloud.run('getFreeTimesToBook', {
        userRoleId: userStore.currentUserRole?.id,
        eventGroupInfoId: groupInfoId,
        schoolId: userStore.currentUserRole.school_pointer.id,
      })
      this.setFreeTimesToBook(freeTimesToBook)
    }
  }

  deleteEvent = async (
    eventTimeId: string | undefined,
    eventGroupInfoId: string | undefined,
    removeFutureRREvents?: boolean,
  ) => {
    if (userStore.currentUserRole && userStore.currentUserRole.school_pointer) {
      const response = await Parse.Cloud.run('deleteEvent', {
        userRoleId: userStore.currentUserRole.id,
        schoolId: userStore.currentUserRole.school_pointer.id,
        eventTimeId: eventTimeId,
        eventGroupInfoId: eventGroupInfoId,
        removeFutureRREvents: removeFutureRREvents,
      })

      if (this.events && this.allEvents) {
        this.setEvents(
          this.events?.filter(
            (event) => event.eventGroupInfoId !== eventGroupInfoId,
          ),
        )
        this.setAllEvents(
          this.allEvents?.filter(
            (event) => event.eventGroupInfoId !== eventGroupInfoId,
          ),
        )
      }
    }
  }
  deleteBookingEvent = async (eventGroupInfoId: string | undefined) => {
    if (userStore.currentUserRole && userStore.currentUserRole.school_pointer) {
      const response = await Parse.Cloud.run('deleteEventFomGroupInfoId', {
        userRoleId: userStore.currentUserRole.id,
        schoolId: userStore.currentUserRole.school_pointer.id,
        eventGroupInfoId: eventGroupInfoId,
      })

      if (this.events && this.allEvents) {
        this.setEvents(
          this.events?.filter(
            (event) => event.eventGroupInfoId !== eventGroupInfoId,
          ),
        )
        this.setAllEvents(
          this.allEvents?.filter(
            (event) => event.eventGroupInfoId !== eventGroupInfoId,
          ),
        )
      }
    }
  }

  editEvent = async (
    eventTimeId: string | undefined,
    eventGroupInfoId: string | undefined,
    topic?: string,
    content?: string,
    files?: any[],
    fileIds?: string[],
    tagIds?: string[],
    locationId?: string,
    maxParticipants?: number,
    depIds?: string[],
    newTimeSpan?: string[],
    onlyStaff?: boolean,
    specificRoleIds?: string[],
    publishAs?: string,
    eventResponsible?: string,
    isPinned?: boolean,
    sendPush?: boolean,
  ) => {
    if (userStore.currentUserRole && userStore.currentUserRole.school_pointer) {
      if (files) {
        // Skapa Parse Files
        const filesParse = []
        for (const file of files) {
          if (!!file.base64Image) {
            const newFile = await this.uploadFileToParse(
              file,
              userStore.currentUserRole,
              userStore.currentUserRole.school_pointer.id,
            )
            filesParse.push(newFile)
          }
        }
        if (fileIds)
          fileIds = [...fileIds, ...filesParse.map((file) => file.id)]
      }
      const response = await Parse.Cloud.run('editEvent', {
        userRoleId: userStore.currentUserRole.id,
        eventTimeId: eventTimeId,
        eventGroupInfoId: eventGroupInfoId,
        topic,
        content,
        files,
        fileIds,
        tagIds,
        locationId,
        maxParticipants,
        depIds,
        newTimeSpan,
        onlyStaff,
        specificRoleIds,
        publishAs,
        eventResponsible,
        isPinned,
        sendPush,
      })
      this.setRefreshEvents(true)
      this.getEvents()
    }
  }
}

export const event3Store = (() => {
  return new Event3Store()
})()
export const Event3StoreContext: React.Context<Event3Store> =
  React.createContext(event3Store)
